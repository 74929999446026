import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-choice-coach',
  templateUrl: './m-choice-coach.component.html',
  styleUrls: ['./m-choice-coach.component.css']
})
export class MChoiceCoachComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
