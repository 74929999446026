import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @Output() link = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onLink({ url }: { url: string }) {
    this.link.emit(url);
   }
}
