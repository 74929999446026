import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-home-individual',
  templateUrl: './home-individual.component.html',
  styleUrls: ['./home-individual.component.css']
})
export class HomeIndividualComponent implements OnInit {

  @Output() link = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onLink({ url }: { url: string }) {
   this.link.emit(url);
  }

}
