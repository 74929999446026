import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-problems',
  templateUrl: './m-problems.component.html',
  styleUrls: ['./m-problems.component.css']
})
export class MProblemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
