import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-home-intro',
  templateUrl: './home-intro.component.html',
  styleUrls: ['./home-intro.component.css']
})
export class HomeIntroComponent implements OnInit {

  constructor(private animateScrollService: NgAnimateScrollService) { }

  ngOnInit() {
  }

  navigateToPersonalCoach(duration?: number) {
    this.animateScrollService.scrollToElement('personalCoach', duration);
  }

  navigateToBusinessCoach(duration?: number) {
    this.animateScrollService.scrollToElement('businessCoach', duration);
  }

}
