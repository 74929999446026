import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactComponent } from './contact/contact.component';
import { PublicationComponent } from './publication/publication.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { LegalMentionComponent } from './legal-mention/legal-mention.component';
import { HomeParallaxComponent } from './home-parallax/home-parallax.component';
import { HomeIntroComponent } from './home-intro/home-intro.component';
import { HomeIndividualComponent } from './home-individual/home-individual.component';
import { HomeLeaderComponent } from './home-leader/home-leader.component';
import { MYouBringComponent } from './m-you-bring/m-you-bring.component';
import { MMyMethodComponent } from './m-my-method/m-my-method.component';
import { MTailoredComponent } from './m-tailored/m-tailored.component';
import { MProblemsComponent } from './m-problems/m-problems.component';
import { MImprovementComponent } from './m-improvement/m-improvement.component';
import { MChoiceCoachComponent } from './m-choice-coach/m-choice-coach.component';

import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatCardModule, MatGridListModule, MatListModule,
  MatProgressBarModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSelectModule,
  MatTooltipModule, MatChipsModule, MatAutocompleteModule, MatExpansionModule, MatSidenavModule, MatSnackBarModule, MatTabsModule,
  MatSlideToggleModule, MatSliderModule, MatRadioModule, MatButtonToggleModule, MatBadgeModule, MatTreeModule,
  MatRippleModule, MatSortModule, MatFormFieldModule, MatIconModule
} from '@angular/material';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { AboutCarouselComponent } from './about-carousel/about-carousel.component';
import { PublicationItemComponent } from './publication-item/publication-item.component';
import { BackendComponent } from './backend/backend.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    PublicationComponent,
    MenuComponent,
    HomeComponent,
    AboutComponent,
    FooterComponent,
    LegalMentionComponent,
    HomeParallaxComponent,
    HomeIntroComponent,
    HomeIndividualComponent,
    HomeLeaderComponent,
    MYouBringComponent,
    MMyMethodComponent,
    MTailoredComponent,
    MProblemsComponent,
    MImprovementComponent,
    MChoiceCoachComponent,
    BackToTopComponent,
    AboutCarouselComponent,
    PublicationItemComponent,
    BackendComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatRippleModule,
    MatTreeModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
