import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent {

  links = ['ACCUEIL', 'À PROPOS', 'LE SAVEZ-VOUS ?', 'CONTACT'];
  activeLink = 'ACCUEIL';
  right = true;

  constructor(
    private animateScrollService: NgAnimateScrollService
  ) { }


  public choiceLink(link) {
    this.activeLink = link;
    this.animateScrollService.scrollToElement('top', 0);
  }

  onLink({ url }: { url: string }) {
    this.activeLink = url;
   }

}
