import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-carousel',
  templateUrl: './about-carousel.component.html',
  styleUrls: ['./about-carousel.component.css']
})
export class AboutCarouselComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
