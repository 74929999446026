import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-tailored',
  templateUrl: './m-tailored.component.html',
  styleUrls: ['./m-tailored.component.css']
})
export class MTailoredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
