import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  @Output() link = new EventEmitter<string>();

  constructor(
    private animateScrollService: NgAnimateScrollService
  ) { }

  ngOnInit() {
  }

  public redirectContact() {
    this.animateScrollService.scrollToElement('top', 0);
    this.link.emit('CONTACT');
  }

}
