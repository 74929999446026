import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-home-parallax',
  templateUrl: './home-parallax.component.html',
  styleUrls: ['./home-parallax.component.css']
})
export class HomeParallaxComponent implements OnInit {

  constructor(
    private animateScrollService: NgAnimateScrollService
  ) { }

  ngOnInit() {
  }

  navigateToHeader(duration?: number) {
    this.animateScrollService.scrollToElement('header', duration);
  }

}
